import { useContext, useState, useEffect, useCallback }  from 'react';
import Typography                           from '@mui/material/Typography';
import Box                                  from '@mui/material/Box';
import TextField                            from '@mui/material/TextField';
import FormControlLabel                     from '@mui/material/FormControlLabel';
import FormControl                          from '@mui/material/FormControl';
import FormHelperText                       from '@mui/material/FormHelperText';
import FormLabel                            from '@mui/material/FormLabel';
import InputLabel                           from '@mui/material/InputLabel';
import Select                               from '@mui/material/Select';
import Switch                               from '@mui/material/Switch';
import Checkbox                             from '@mui/material/Checkbox';
import RadioGroup                           from '@mui/material/RadioGroup';
import Radio                                from '@mui/material/Radio';
import MenuItem                             from '@mui/material/MenuItem';
import Button                               from '@mui/material/Button';
import { SelectChangeEvent }                from '@mui/material/Select';
import { get, set, isEmpty, every, 
         cloneDeep 
        }                                   from 'lodash-es';
import { console_log }                      from "@/libs/debug"
import { throwCustomError }                 from "@/libs/debug"

import { iFormController_schema,
         iFormController_schema_field, 
         iFormController_schema_function_prepost,
         iFormController_schema_functions_prepost,
         iFormController_schema_functions_CRUD_prepost,
         iFormController_ReactHookForm_FormValues,
         iFormController_body,
         iFormController_body_field, 
         iFormController_body_where,
         tValue, tRecordField, tDBAction }  from '@/libs/interfaces';
import { isTrue }                           from '@/libs/general';
import DebugBox                             from '@/components/DebugBox';
import moment                               from 'moment';
const moment_format = "YYYY-MM-DD";


//import { TryCatch_CLIENT }                  from '@/libs/db_prisma';
//import { Context_FormController }           from '@/context/Context_FormController';


//CON esta función puedo cambiar cualquier propiedad de un field 
//...cuyo name === pFieldName
//...con el pFieldValue 
//pFieldPropertyName: es opcional aunque no tenga la ? ya que si no se indica su valor por defecto es "value"
export function FormController_Change_FormSchema_Field_Property_v0   (pFormSchema: iFormController_schema,
                                                                    pFieldName: string, 
                                                                    pFieldPropertyValue: tValue,
                                                                    pFieldPropertyName: string = "value" //por defecto "value", POR ESO pongo la ultima este parametro, porque muchas veces no se indica
                                                                            ): iFormController_schema {

    //let property_name = pFieldPropertyName ? pFieldPropertyName : "value";
    const updatedFormSchema = {
        ...pFormSchema,
        fields: pFormSchema.fields?.map(field => {
            if (field.name === pFieldName) {
                return {
                    ...field,
                    [pFieldPropertyName]: pFieldPropertyValue
                    };
                }
            return field;
            })
        };
    return updatedFormSchema;
    }

export function FormController_Change_FormSchema_Field_Property(
                                                                pFormSchema: iFormController_schema,
                                                                pFieldName: string, 
                                                                pFieldPropertyValue: any, // Asumiendo que tValue es un tipo definido que puede ser cualquier cosa
                                                                pFieldPropertyName: string = "value" // Por defecto "value", se suele usar para pasar "DB_items" cuando quiero cargar un field de tipo select
                                                            ): iFormController_schema {
    
    // Actualización de los fields
    const updatedFields = pFormSchema.fields?.map(field => {
        if (field.name === pFieldName) {
            // Actualización del campo especificado
            /* return {
                ...field,
                [pFieldPropertyName]: pFieldPropertyValue
                }; */
            // para permitir actualizar propiedades anidadas utilizo la función set de lodash, por lo que lo anterior lo hago asi...
            const updated_field = { ...field };
            // Usar lodash para establecer la propiedad anidada
            set(updated_field, pFieldPropertyName, pFieldPropertyValue);
            return updated_field;
            }
        return field;
        });

    // Determinar el DB_field del campo actualizado para luego actualizar DB_data
    const fieldToUpdate = pFormSchema.fields?.find(field => field.name === pFieldName);
    const dbFieldKey = fieldToUpdate?.DB_field;

    // Actualización de DB_data solo si dbFieldKey existe y es una key válida en DB_data
    let updatedDBData = {...pFormSchema.DB_data};
    if (dbFieldKey && pFormSchema.DB_data && pFormSchema.DB_data.hasOwnProperty(dbFieldKey)) {
        updatedDBData = {
            ...pFormSchema.DB_data,
            [dbFieldKey]: pFieldPropertyValue
            };
        }

    // Retornar la estructura actualizada
    return {
        ...pFormSchema,
        fields: updatedFields,
        DB_data: updatedDBData
        };
}
    


//Creo una copia de stt_FormSchema y le cambio los valores de los fields que coincidan con los de pDB_data
//particularidades de esta función
// - en vez de cambiar el estado fuera, aqui dentro devuelvo una copia del estado con los valores actualizados
//   para fuera decidir si lo cambio o no y cuando ya que dicha copia puede tener aún mas cambios
// - permito vaciar todos los values de los field si paso un pDB_data undefined o vacío
//p_prepost_actions: parametro opcional para indicar si hay que ejecutar alguna función pre o post
export function FormController_new_stt_FormSchema(
                                            p_stt_FormSchema: iFormController_schema, 
                                            pDB_data?: any, //si es undefined no actualizo los valores de los fields con este pareametro
                                            p_CRUD_prepost_actions?: iFormController_schema_functions_CRUD_prepost,
                                            //pDB_action?: tDBAction
                                            ): iFormController_schema {

    //const new_FormSchema = JSON.parse(JSON.stringify(p_stt_FormSchema));
    const new_FormSchema = cloneDeep(p_stt_FormSchema);
    //console.log ("p_stt_FormSchema", p_stt_FormSchema);
    //console.log ("new_FormSchema", new_FormSchema);



    // Preparando el objeto DB_data si no está presente en el esquema
    if (!new_FormSchema.DB_data) {
        new_FormSchema.DB_data = {};
        }

    //2024_05_06: añadido para poder crear copias del formSchema para insertar y borrar dentro del propio FormController
    //if (pDB_action) {
    //    new_FormSchema.DB_action = pDB_action;
    //    }



    //const pDB_data_empty_object = !pDB_data || (Object.keys(pDB_data).length === 0 && pDB_data.constructor === Object);
    const pDB_data_empty_object = pDB_data !== null && pDB_data !== undefined && Object.keys(pDB_data).length === 0 && pDB_data.constructor === Object;

    //cuando pDB_data es {}
    if (pDB_data_empty_object) {
        if (new_FormSchema.DB_data) {
            Object.keys(new_FormSchema.DB_data).forEach(key => {
                new_FormSchema.DB_data![key] = null;
                });
            }
        if (new_FormSchema.fields) {
            new_FormSchema.fields = new_FormSchema.fields.map(field => ({
                ...field,
                value: null  // Vaciar el valor de cada field, o ajustar según necesidad
                }));
            }
        }
    //sino es un objeto vacío {} compruebo a ver si existe
    else if (pDB_data) {
        //de lo obtenido en la bbdd miro a ver si existe la propiedad que me interesa en las diferentes ubicaciones que puede estar:
        //...directamente en pDB_data
        //...sino en pDB_data.response
        //...sino en pDB_data.extended_response.response
        //...si pDB_data es un array, devuelvo el primer elemento
        //let DB_data = JSON.parse(JSON.stringify(pDB_data)); //* con este nombre de variable hay algún problema que no se carga, asi que utilizo...
        //let DB_data2 = JSON.parse(JSON.stringify(pDB_data));
        let DB_data2: any

        DB_data2 = cloneDeep(pDB_data);
        if      (pDB_data?.response)                        {DB_data2 = cloneDeep(pDB_data.response);}
        else if (pDB_data?.extended_response?.response)     {DB_data2 = cloneDeep(pDB_data.extended_response.response);}
        //if (DB_data?.extended_response?.response[0])  {DB_data = pDB_data.extended_response.response[0];}
        if (Array.isArray(DB_data2) && DB_data2.length > 0) {DB_data2 = cloneDeep(DB_data2[0]);}

        // Actualizando la propiedad new_FormSchema.DB_data con los valores de DB_data2 recien hayados
        //if (new_FormSchema.DB_data) {
        //    Object.keys(new_FormSchema.DB_data).forEach(key => {
        if (new_FormSchema.DB_data) {
            Object.keys(DB_data2).forEach(key => {
                new_FormSchema.DB_data![key] = DB_data2[key];
                });
            }

        // Actualizando los campos en la copia
        new_FormSchema.fields = new_FormSchema.fields?.map((field: iFormController_schema_field) => { 
            //console.log ("DB_data", DB_data2);
            let new_field = field;
            /* if      (pDB_data_empty)    { 
                // Si pDB_data está vacío, inicializo los field para que no lleven un valor que no sea de su tipo
                // tb podría poner null siempre... por ahora lo dejo asi...
                let field_value_initial;
                switch (field.type) {
                    case 'text' : case 'string' : case 'textarea'   : field_value_initial = ''  ; break;
                    case 'number'                                   : field_value_initial = 0   ; break;
                    //case 'hidden'                                 : field_value_initial = null; break;
                    //case 'boolean'                                : field_value_initial = null; break;
                    default                                         : field_value_initial = null;
                    }
                new_field = {...field, value: field_value_initial };
                } 
            //cambio esta consulta ya que si el valor es 0, "",... me lo ignoraba
            //else if (field.DB_field && pDB_data[field.DB_field]) { new_field = {...field, value: pDB_data[field.DB_field]};}
            //con lo siguiente miro a ver si la propiedad existe 
            else  */
            
            if (field.DB_field )   { 
                /* if (field.name === "tipo_usuario") {
                    console.log ("field", field);
                    } */
                /* if      (field.DB_field in pDB_data)                {response = pDB_data;}
                if (DB_data && field.DB_field in DB_data) {
                    dbFieldData = field;
                    } */
                //new_field = {...field, value: get(DB_data2, field.DB_field, null)}; //para asignar null en vez de undefined si el get no encuentra el DB_field
                //si existe esta propiedad en DB_data2 lo asigna al field.value
                new_field = {...field, value: get(DB_data2, field.DB_field)};
                }
            //si existe un DB_field definido asigno a [key: string]: any;
            //...key el DB_field 
            //...value el value (exista o no)
            if (new_field.DB_field) {
                //new_field[new_field.DB_field] = new_field.value;
                if (new_FormSchema.DB_data) {
                    new_FormSchema.DB_data[new_field.DB_field] = new_field.value;
                    }
                }

            return new_field;
            });
            


        }




    //if (!new_FormSchema.prepostActions) {new_FormSchema.prepostActions = {};}
    //if (!p_CRUD_prepost_actions) {new_FormSchema.prepostActions = {};}
    if (p_CRUD_prepost_actions) {
        //aunque con lo siguiente y una acutalización del interfaz iFormController_schema_functions_CRUD_prepost añadiendo
        //      [key: string]: iFormController_schema_functions_prepost | undefined;
        //podría usar lo sigiente
        //      new_FormSchema.prepostActions["on" + capitalize(p_prepost_actions.preAction?.on)]  = p_prepost_actions.preAction;
        //no lo hago para no complicarlo y que quede muy abierta la definición de dicha interfaz, asi que hago...

        if (!new_FormSchema.prepostActions) {new_FormSchema.prepostActions = {};}
        if (p_CRUD_prepost_actions.onCreate) {new_FormSchema.prepostActions.onCreate = p_CRUD_prepost_actions.onCreate;}
        if (p_CRUD_prepost_actions.onUpdate) {new_FormSchema.prepostActions.onUpdate = p_CRUD_prepost_actions.onUpdate;}
        if (p_CRUD_prepost_actions.onDelete) {new_FormSchema.prepostActions.onDelete = p_CRUD_prepost_actions.onDelete;}

        //aunque entiendo que es lo de arriba por ahora tb mantengo lo siguiente hasta confirmar todo el circuito
        /* if (p_prepost_actions.preAction.on === "create") {new_FormSchema.prepostActions.onCreate = p_prepost_actions.preAction;}
        if (p_prepost_actions.preAction.on === "update") {new_FormSchema.prepostActions.onUpdate = p_prepost_actions.preAction;}
        if (p_prepost_actions.preAction.on === "delete") {new_FormSchema.prepostActions.onDelete = p_prepost_actions.preAction;}
        if (p_prepost_actions.postAction.on === "create") {new_FormSchema.prepostActions.onCreate = p_prepost_actions.postAction;}
        if (p_prepost_actions.postAction.on === "update") {new_FormSchema.prepostActions.onUpdate = p_prepost_actions.postAction;}
        if (p_prepost_actions.postAction.on === "delete") {new_FormSchema.prepostActions.onDelete = p_prepost_actions.postAction;}  */






        }
        

    return new_FormSchema;
    }

//2024_09_30: recorre todo el schema y crea un clon inicializando solo los fields marcados con "initialize_on_create"
//            necesario para resetear correctamente los stt_FormSchema_create
export function FormController_new_stt_FormSchema_with_only_initialize_on_create(p_stt_FormSchema: iFormController_schema) {
    //inicialmente para stt_FormSchema_create la creabamos vacia...
    //let cloned_FormSchema = FormController_new_stt_FormSchema(props.p_stt_FormSchema, {}); //esta {} vacia de contenido
    //pero puede darse el caso de que algún campo deba estar inicializado, ya que es necesario para dar un alta (por ejemplo una clave foranea)
    //para ello partiendo de {} si algún campo tiene la propiedad initialize_on_create == true, lo añadimos...
    let DB_data: { [key: string]: any } = {}; //partimos de un objeto vacio y...
    //... si algun field tiene initialize_on_create a true lo añadimos
    p_stt_FormSchema.fields?.forEach((field) => {
        if (field.initialize_on_create && field.DB_field) {
            DB_data[field.DB_field] = field.value;
            }
        });
    let cloned_FormSchema = FormController_new_stt_FormSchema(p_stt_FormSchema, DB_data);
    return cloned_FormSchema;
    }


//2024_02_12, para que maneje tb los eventos de Select
export const FormController_HandleFieldChange = (
                                                p_stt_FormSchema: iFormController_schema,
                                                p_sstt_FormSchema: React.Dispatch<React.SetStateAction<iFormController_schema>>
                                                //) => (pEvent: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent) => {
                                                ) => (pEvent: React.ChangeEvent<{ name?: string; value: unknown; checked?: boolean; }> | SelectChangeEvent) => {
 
    // Desde la introducción de tipos de campo SELECT el evento tb puede ser SelectChangeEvent, por lo que debo hacer una...
    // discriminación de tipos basada en la existencia de la propiedad 'target.name'

    // Extraer el nombre y determinar el valor basado en el tipo de evento
    //let name: string;
    //let value: string | number | boolean;
    const target = pEvent.target as HTMLInputElement; // Asumir HTMLInputElement para cubrir inputs y switches
    const name = target.name;
    let value: tValue;

 
    // Discriminación de tipos para manejar el evento
    if (target.type === 'checkbox') { //¿¿¿ esto no seria switch ???
        // Caso para Switch
        value = isTrue(target.checked);
        } 
    else if (target.type === 'radio') {
        // Caso para RadioGroup
        console.log ("target.value", target.value);
        value = target.value;
        }
    else if (target.type === 'date') { 
        //2024_10_16
        const date_value = (typeof target.value === 'string') ? moment(target.value, moment_format, true) // Si el valor es una cadena, intenta crear un objeto moment con el formato específico (estricto, el true del 3er parametro)
                                                              : moment(target.value);                     // De lo contrario, crea un objeto moment directamente  
        value = (date_value.isValid()) ? date_value.format(moment_format + "THH:mm:ss.SSS[Z]")  // Si la fecha es válida, devuelve la fecha formateada en el formato deseado
                                       : null;                                                  // Si no es válida, devuelve null
        }
    else {
        // Caso general para Input y Select
        // fuerzo cuando esta vacia a null para que en las select se pueda elegir una opcíon que borre en la bd
        value = (target.value === 'null' || target.value === '' ) ? null : target.value;
        }
   
    

    // Obtengo los fields actualizados
    const new_Fields = p_stt_FormSchema.fields?.map(field => {
        if (field.name === name) { return { ...field, value: value }; } 
        else                     { return field; }
        });

    // obtengo el DBData actualizado
    const new_DBData = { ...p_stt_FormSchema.DB_data };
    const field_to_update = new_Fields?.find(field => field.name === name);
    if (field_to_update && field_to_update.DB_field) {
        new_DBData[field_to_update.DB_field] = value;
        }
       
    // Actualizar la información
    let new_FormSchema = {
                            ...p_stt_FormSchema,
                            fields: new_Fields,
                            DB_data: new_DBData
                            };
    new_FormSchema.data_modified_by_user = true; 

    p_sstt_FormSchema(new_FormSchema);
    };
    

  
export const FormController_Create_RequestBody = (p_stt_FormSchema: iFormController_schema) => {
    // Preparar los datos del formulario para enviar
    //como funciona reduce,
    // - empieza con lo dfinido al final, en este caso []
    // - y en cada iteración va añadiendo lo que le diga en el return
    //acc: representa lo que se va acumulando tras cada iteración
    const arrayFields = p_stt_FormSchema.fields?.reduce<iFormController_body_field[]>
        (
        (acc, field) => {
                        //con fines de depuración
                        if (field.name === "fecha_nacimiento") {
                            //console.log ("field", field);
                            }
                        const objField: iFormController_body_field = {
                            name: field.name,
                            //value: field.value || '',
                            //lo anterior me devolvia '' cuando era 0
                            //2024_07_27: sustituyo lo siguiiente...
                            //value: (field.value !== undefined) ? field.value : '',
                            //por, esto otro porque sino en los campos select cuando se quiere pasar un valor vacio, se pasaba '' y daba casque al intentar grabar en la bd
                            //habrá que estar atentos a posibles efectos colaterales
                            //value: (field.value !== undefined) ? field.value : undefined,
                            //esto anterior podría ser "value: field.value" ...
                            value: field.value,
                            type: field.type,
                            DB_field: field.DB_field || ''
                            };

                        switch (field.type) {
                            case 'number': 
                                // Verificar si el valor es numérico
                                //const field_value_number = parseInt(field.value as string, 10);
                                const field_value_number = parseFloat(field.value as string); // Utilizamos parseFloat para permitir números decimales
                                if (!isNaN(field_value_number)) {
                                    objField.value = field_value_number; // Asignar el valor numérico
                                    } 
                                else {
                                    objField.value = 0; // Si no es numérico, asignar 0 u otro valor predeterminado según se desee
                                    }
                                break;
                            case 'date': 
                                if (!objField.value ) {
                                    objField.value = null;
                                    } 
                                else {
                                    //para evitar que sume la franja horaria local lo hago con el siguiente formato...
                                    //tras muchas pruebas consigo que se guarde de la siguiene forma...
                                    const objField_value_with_format = ( typeof objField.value === 'string' ) ? moment(objField.value, moment_format).format(moment_format + "THH:mm:ss.SSS[Z]")
                                                                                                              : objField.value;
                                    objField.value = objField_value_with_format;
                                    }
                                break;
                            case 'switch':
                                objField.value = isTrue(objField.value); // por si está en formato string, vacio,...
                                break;
                            case 'select':
                                //si solo hay 1 elemento en el select o el primero no es null o undefined (y está seleccionado), lo asigno directamente
                                //necesario para que al guardar se refleje en la bd
                                if (field.DB_items && field.DB_items.length > 0 && 
                                        (
                                        field.DB_items.length == 1 //solo hay 1 elemento
                                        //por ahora ignoro la siguiente que pretendia controlar que si el primer elemento es diferente de null que lo asignara, pero quizas no sea el seleccionado
                                        //y como por el momento no veo que haga falta no me peleo mas con ello.
                                        //|| (field.DB_items[0].value != null && field.DB_items[0].value != 'null') 
                                        )
                                    ) {
                                    objField.value = field.DB_items[0].value; 
                                    }
                                
                                break;
                            }

                        // Agregar DB_is_pk solo si es true
                        if (field.DB_is_pk) {objField.DB_is_pk = true;}
                        acc.push(objField);
                        return acc;
                        }, []
        ); // valor inicial de acc es un array vacío

    /* // Identificar el campo que es clave primaria
    const pkField = p_stt_FormSchema.fields.find(field => field.DB_is_pk);
    // Crear el objeto where si hay un campo pk
    let obj_where_old = undefined;
    if (pkField && pkField.DB_field && pkField.value) {
        obj_where_old = {
            DB_field: pkField.DB_field,
            value: pkField.value,
            //operator: '=' // o el operador que necesites
            };
        } */
    //2024_01_16: lo hago con un formato similar al de prisma
    //            por lo que con el siguiente proceso convierto el form_schema a dicho formato
    //            comparando cada pk_field con su valor
    let obj_where: iFormController_body_where | undefined = undefined; 
    const pkFields = p_stt_FormSchema.fields?.filter(field => field.DB_is_pk); // Identificar todos los campos que son claves primarias
    if (pkFields && pkFields?.length > 0) {
        obj_where = pkFields.reduce((acc, field) => {
            if (field.DB_field && field.value !== undefined) {
                acc[field.DB_field] = field.value;
                }
            return acc;
            }, {} as iFormController_body_where);
        }



    // Ahora crea el objeto formData y asigna el array a la propiedad fields
    let prepostActions:iFormController_schema_functions_prepost | undefined;
    //si intento hacer lo siguiene asi...
    //      if      (["create", "insert"].includes(p_stt_FormSchema.DB_action)) {prepostActions = p_stt_FormSchema.prepostActions?.onCreate}
    //da error porque p_stt_FormSchema.DB_action es de tipo tDBAction y es implica que puede ser undefined
    let db_action = p_stt_FormSchema.DB_action;
    if  (db_action === "upsert") {
        //debo controlar...
        //...si obj_where es vacia
        //...si todas las propiedades de obj_where son null
        //en esos casos es una insert
        //y con lodash se haría asi...
        db_action = (isEmpty(obj_where) || every(obj_where, value => value === null))? "insert" : "update";
        }
    
    if      (db_action === "create" || db_action === "insert")  {prepostActions = p_stt_FormSchema.prepostActions?.onCreate}
    else if (db_action === "update")                            {prepostActions = p_stt_FormSchema.prepostActions?.onUpdate}
    else if (db_action === "delete")                            {prepostActions = p_stt_FormSchema.prepostActions?.onDelete}
    else    {prepostActions = {}} //si no es ninguno de los anteriores, lo dejo vacio
    
    const formData: iFormController_body = {
        DB_table                            : p_stt_FormSchema.DB_table,
        DB_action                           : db_action, 
        DB_data                             : p_stt_FormSchema.DB_data, 
        fields                              : arrayFields,
        where                               : obj_where,
        prepostActions                      : prepostActions,
        loginAction                         : p_stt_FormSchema.loginAction,
        search_possible_records_to_insert   : p_stt_FormSchema.search_possible_records_to_insert,
        };

    return formData
    }


export function FormController_Get_Data(p_FormSchema: iFormController_schema): tRecordField {
    const result: tRecordField = {};

    p_FormSchema.fields?.forEach(field => {
        let defaultValue = null
        switch (field.type) {
            case 'float': case 'number':    defaultValue = 0;       break;
            case 'boolean':                 defaultValue = false;   break;
            case 'string':                  defaultValue = '';      break;
            //default:                        defaultValue = null; // O cualquier otro valor predeterminado
            }

        if (field.DB_field) {
            let field_value = FormController_Field_Value (field)
            // @ts-ignore
            result[field.DB_field] = (field_value !== undefined) ? field_value : defaultValue;
            }
        });

    return result;
    }
    

// VALUE 
// Encuentra el valor actual del campo en el estado del formulario.
// chatgpt me planteaba esta linea...
//      const fieldValue = p_stt_FormSchema.fields.find(f => f.name === field.name)?.value ?? '';
// pero me es mucho mas legible esta otra versión en 2 lineas...
export function FormController_Field_Value (pField: iFormController_schema_field | string, p_FormSchema?: iFormController_schema) {
    //const foundField = p_stt_FormSchema.fields.find(f => f.name === field.name);
    //let   fieldValue = (foundField && foundField.value) ? foundField.value : '';
    let field_value:tValue = '';
    let field: iFormController_schema_field | undefined; //el undefined es para que vsc no ponga en rojo field mas abajo
    // Si pField es un string, buscamos el campo correspondiente en p_FormSchema
    if (typeof pField === 'string' && p_FormSchema) {
        field = p_FormSchema.fields?.find(f => f.name === pField);
        }
    else if (typeof pField !== 'string') { //necesario este if para que vsc no ponga error en la siguiente linea
        field = pField;
        }

    // Asegurarse de que field esté definido antes de usarlo
    if (!field) {
        throwCustomError("Field no encontrado");
        }

    field_value = field.value || '';

    if (field.name === "fecha_nacimiento") {
        //console.log ("pField", pField);
        } 

    //const fieldValue = (foundField && foundField.value) ? foundField.value : '';
    switch (field.type.toUpperCase()) {
        case 'DATE': 
            //hay ocasiones que la fecha viene en un formato tipo....
            // 1978-03-27T00:00:00.000Z
            //si está vacio me aseguro que field_value sea null (ya que se ha podido inicializar como '' mas arriba)
            if (!field.value) {
                //al final he tenido que poner eso para que se dibuje bien y restaure el valor cuando se cambia de registro (ensayo-error)
                //en el servidor controlo que si viene eso ponga null
                //field_value = 'T00:00:00.000Z';
                }
            else if (field_value !== 'T00:00:00.000Z' && (typeof field_value === 'string' || typeof field_value === 'number')) {
                //con lo siguiente consigo que se "normalice"
                //const new_field_value = moment(field_value, 'DD/MM/YYYY'); //asi se hacia en el componente Campeonato, habrá que probar para el resto.
                                                                           //pero eso hace que no funcione bien al manipuar las fechas con el input date de mui
                                                                           //asi que si tengo que hacer alguna manipulación de fechas lo hago antes de pasarla al formController,
                                                                           //fijarse como está en el componente Campeonato
                //field_value = moment(field_value).format(moment_format) //+ 'T00:00:00.000Z'.trim();
                //field_value = moment(field_value, moment_format).format(moment_format + "THH:mm:ss.SSS[Z]") //2024_10_16
                //si añado + "THH:mm:ss.SSS[Z]" no se dibuja bien en el componente de mui
                field_value = moment(field_value, moment_format).format(moment_format) //2024_10_16
                }   
            break;
        case 'RADIO': case 'RADIOS': 
            //de ser una opción de radios, en value habrá un array de posibles valores
            //por ahora selecciono el primero de estar vacio
            //quizas tb debiera controlarse que no este disabled
            if (!field.value) {
                //NO inicializo con nada, ya que debe permitirse que no se seleccione ninguna opción
                //field_value = ((Array.isArray(pField.radio_options?.values) && pField.radio_options?.values.length > 0)) ? pField.radio_options?.values[0] : null;
                field_value = null; //hay que poner esto porque sino se vuelve loco y cambia entre jugador-delegado indefinidamente
                }

            break;
        }
    return field_value;
    } 


// DISABLED 
// tomo las decisiones necesarias para saber si hay que deshabilitar el campo
// puede ser que esté determinado por otro/S campo/S, asi que hago...
export function FormController_Field_Disabled (pField: iFormController_schema_field, p_stt_FormSchema: iFormController_schema) {
    const objConf = FormController_Configuration(p_stt_FormSchema)

    //con fines de depuración
    /* if (pField.name === "tipo_usuario" && p_stt_FormSchema.DB_data) {
        //console.log ("pField", pField);
        } */

    let is_disabled = false;
    if      (objConf.force_fields_disabling === true)   { is_disabled = true}
    else if (typeof pField.disabled === 'boolean')      { is_disabled = pField.disabled}
    //else if (typeof field.disabled === 'object'  && field.disabled !== null)    { 
    else if (Array.isArray(pField.disabled)) { 
        //cuando se define como un array es que el campo está disabled en función de otro/s campo/s y debe cumplir  las condiciones para que se desactive
        is_disabled = true;
        // Defino una funcion que tiene en cuenta posibles operator dentro de cada condición
        // Definimos 'evaluator' solo una vez fuera de los bucles y manejamos un operador predeterminado.
        // uso "==" en vez de "===" para que se pueda comparar diferentes tipo, por ejemplo que 5 == "5"
        const evaluator = (pValue1: tValue, pValue2: tValue, pOperator: string = "==") => {
            //lo siguiente es similar a eval pero mas seguro
            return new Function('pValue1', 'pValue2', `return pValue1 ${pOperator} pValue2;`)(pValue1, pValue2);
            };
        
        // Nuevo código que maneja la estructura de condiciones AND y OR en un array
        if (p_stt_FormSchema.DB_data) {// compruebo que hay datos en DB_data, para poder comparar
            const arrConditions = pField.disabled;
            is_disabled = arrConditions.some(condition => {
                const operator1 = condition.operator || 'OR';
                if (operator1 === "AND") {
                    //return condition.rules.every(rule => p_stt_FormSchema.DB_data![rule.field] === rule.value);
                    //return condition.rules.every(rule => evaluator(p_stt_FormSchema.DB_data[rule.field], rule.value, rule.operator));
                    //lo anterior podria sustituir a estas otras 4 lineas pero lo pongo asi para que se vea mas clara la diferencia entre el operator1 y el operator2
                    return condition.rules.every(rule => {
                        const operator2 = rule.operator;
                        return evaluator(p_stt_FormSchema.DB_data?.[rule.field], rule.value, operator2);
                        });
                    } 
                else if (operator1 === "OR") {
                    //return condition.rules.some(rule => p_stt_FormSchema.DB_data![rule.field] === rule.value);
                    return condition.rules.some(rule => {
                        const operator2 = rule.operator;
                        return evaluator(p_stt_FormSchema.DB_data?.[rule.field], rule.value, operator2);
                        });
                    }
                return false;
                });
            //console.log("is_disabled: ", is_disabled);
            }
        }
    return is_disabled;
    } 


// VALIDATION 
// Realizo aqui las comprobaciones necesarias para saber si hay un error en la validación del campo
export function FormController_Field_Validation (pField: iFormController_schema_field, p_stt_FormSchema: iFormController_schema) {
    if (pField.name == "fecha_entrada_alojamiento_club") {
        //console.log ("depurar validation of fecha_entrada_alojamiento_club");
        //console.log ("field_disabled", field_disabled);               //no se porque esto provoca un comportamiento raro
        //console.log ("field_disabled: " + field_disabled.toString()); //y esto, es al intentar mostrar field_disabled
        }

    const field_value    = FormController_Field_Value (pField);
    const field_disabled = FormController_Field_Disabled (pField, p_stt_FormSchema);
    let error_required = pField.validation?.required && !field_value
    //let error_minLength = field.validation?.minLength && fieldValue && fieldValue.length < field.validation.minLength
    let error_minLength = pField.validation?.minLength && typeof field_value === 'string' && field_value.length < pField.validation.minLength;



    //ahora en función del tipo de error que permanezca activo, muestro el mensaje de error correspondiente
    let error_message;
    if (error_minLength) {
        error_message = pField.validation?.minLength_message || 'Debes introducir un valor de al menos ' + pField.validation?.minLength + ' caracteres';
        }

    if (error_required) {
        error_message = pField.validation?.required_message || 'Debes introducir un valor';
        }

    //si está deshabilitado no muestro error
    if (field_disabled) {error_message = null} //si está deshabilitado no puede tener error de required

    //si estamos en desarrollo y el campo no es "nombre" tb lo quito para evitar teniendo que meter datos en todos los campos aunque dejo 1, nombre.
    if (process.env.NODE_ENV === 'development' && pField.name != "nombre") {error_message = null}

    return (error_message) ? error_message : true;
    } 

export const FormController_ALL_Fields_Validation = ( p_stt_FormSchema: iFormController_schema) => {
    // Usamos every para asegurarnos de que todos los campos sean validados correctamente.
    return p_stt_FormSchema.fields?.every(field => {
        const validationResult = FormController_Field_Validation(field, p_stt_FormSchema);
        // Retornamos true si la validación es correcta, en caso contrario debemos asegurarnos de que se retorne false o un mensaje de error.
        return validationResult === true; 
        });
    }



export function FormController_Fields_Render (p_stt_FormSchema: iFormController_schema,
                                              p_sstt_FormSchema: React.Dispatch<React.SetStateAction<iFormController_schema>>) {


    //------------------------------------------------------------------------------------
    // RESETEAR si DESHABILITADO
    // se me habia ocurrido la brillante idea de vaciar los campos que estén deshabilitados por las condiciones del schema para 
    //  - que sea mas intuitivo para el usuario                                                
    //  - no se envie a la bbdd 
    // pero tras "invertir" unas horas me he dado cuenta que...
    //  - impediria que se vieran valores que deben verse aunque esté inhabilitado ese campo 
    //  - no consigo hacerlo bien del todo, aunque si se borran pero en el p_stt_FormSchema no acaban de borrarse
    //asi que comento todo el codigo relacionado con esto (marcandolo con RESETEAR si DESHABILITADO) y lo dejo para mas adelante si es que vuelvo sobre ello                                                
    /*
    let new_FormSchema: iFormController_schema = {};
    let update_new_FormSchema: boolean = false;
    useEffect(() => {
        if (!isEmpty(new_FormSchema)) {
            p_sstt_FormSchema(new_FormSchema);
            new_FormSchema = {};
            }
    }, [update_new_FormSchema]);

    const reset_New_FormSchema_Field_Value = (fieldName: string) => {
        if (isEmpty(new_FormSchema)) {new_FormSchema = cloneDeep(p_stt_FormSchema)}
        const field = new_FormSchema?.fields?.find(f => f.name === fieldName);
        const reset_value = (field?.type === 'text') ? '' : null;
        if (field && field.value !== reset_value) {
            field.value = reset_value;
            }
        return reset_value
        };
    */

    const objConf = FormController_Configuration(p_stt_FormSchema)
    //let new_FormSchema: iFormController_schema = {}
    return ( 
        <>
        {
        p_stt_FormSchema.fields?.map((field, index) => {

            //con fines de depuración
            /* if (field.type === "date") {
                console.log ("paro antes de obtener FormController_Field_Value");
                }  */
            let   field_value      = FormController_Field_Value    (field);
            const field_disabled   = FormController_Field_Disabled (field, p_stt_FormSchema);
            const field_validation = FormController_Field_Validation (field, p_stt_FormSchema);
            const field_validation_error    = (typeof field_validation === 'string') ? true : false;
            const field_validation_message  = (typeof field_validation === 'string') ? field_validation : null;

            //pruebo a vaciar el value si esta disabled, por ahora lo hago aqui "a lo bruto", pero quizas haga falta hacerlo dentro de FormController_Field_Value mas adelante ¿seguro, no se pegaria conla actualizacion de p_stt_FormSchema?
            //if (field_disabled) {field_value = null}
            //parece que por ahora sirve aunque hay un comportamiento raro, fijarse en "Ficha Delegado > Tiene Usuario" cuando lo deshabilito se resetean los radios pero no el inputText
            //lo que creo que ocurre es que si que lo deshabilita en la UI pero no lo resetea en el field subyacente del p_stt_FormSchema, por lo que habria que hacerlo ¿asi?...
            /* 
            if (field_disabled && field_value !== null) {
                field_value = null //necesario para que se dibuje mas abajo en esta iteración
                //p_sstt_FormSchema(FormController_Change_FormSchema_Field_Property(p_stt_FormSchema, field.name, field_value));
                if (isEmpty(new_FormSchema)) {new_FormSchema = cloneDeep(p_stt_FormSchema)}
                new_FormSchema = FormController_Change_FormSchema_Field_Property(new_FormSchema, field.name, field_value);
                }  */




/*             if (field.name === "categoria") {
                console.log("------------------------------------------------");
                console.log("name/value/value: ", field.name + " / " + field.value + " / " + field_value);
                console.log("field_disabled: ", field_disabled);
                console.log("field_validation: ", field_validation);
                console.log("\n");
                }    */
            
            // RESETEAR si DESHABILITADO
            /* 
            // Verificar si el campo está deshabilitado y vaciarlo si es necesario
            if (field_disabled && field_value !== null) {
                //la sigiente linea tiene 2 propositos:
                //- establece new_FormSchema con el valor reseteado para este campo
                //- devuelve dicho valor reseteado a field_value para que en este dibujado aparezca resetado
                field_value = reset_New_FormSchema_Field_Value(field.name);; //
                } */

                /* const HandleFieldChange_with_useCallback = useCallback((event: any) => {
                                                                    console.log("dentro de HandleFieldChange_with_useCallback");
                                                                    FormController_HandleFieldChange(p_stt_FormSchema, p_sstt_FormSchema)(event);
                                                                    },
                                                                    [p_stt_FormSchema, p_sstt_FormSchema] // Dependencias del callback
                                                                ); */


            //--------------------------------------------------------------------------------------------------------------------------
            // comienzo el dibujado del campo
            return (
                <Box key={field.name} 
                    //si el tipo es hidden lo oculto aunque seguirá en el dom
                    sx={field.type === 'hidden' ? { display: 'none' } 
                                                : field.box_sx || {}
                       }
                    >
                    <DebugBox 
                        pData={field} 
                        pLabel={field.name ? field.name : 'field.name'}
                        pShow={false}
                        pOnlyTooltip={true}
                        //pExcludedProps={["children"]}
                        pOpenMode={'click'}
                        pWidth={400}
                        />
                    {/* <Box>
                        field.type.toUpperCase(): {field.type.toUpperCase()}
                    </Box> */}
                    {
                    (() => {
                        switch (field.type.toUpperCase()) {
                            case 'LABEL': 
                                return (
                                    <Typography 
                                        sx={{ml:1, ...field.sx}}
                                        >
                                        {field.label}
                                    </Typography>
                                    );
                            case 'CHECKBOX':
                                return (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isTrue(field_value)}
                                                onChange={FormController_HandleFieldChange(p_stt_FormSchema, p_sstt_FormSchema)}
                                                name={field.name}
                                                color="primary"
                                                disabled={field_disabled}
                                                />
                                            }
                                        label={field.label}
                                        //labelPlacement="start"
                                        sx={{marginTop: '10px', ...field.sx}}
                                        />
                                    );
                            case 'SWITCH':
                                return (
                                        <FormControlLabel
                                            control={
                                            <Switch
                                                checked={isTrue(field_value)}
                                                onChange={FormController_HandleFieldChange(p_stt_FormSchema, p_sstt_FormSchema)}
                                                name={field.name}
                                                color="primary"
                                                disabled={field_disabled}
                                            />
                                            }
                                            label={field.label}
                                            sx={{ marginTop: '10px', 
                                                  ...field.sx }}
                                            />
                                        );
                            //break; no hacen falta ya que el return corta la ejecución
                            case 'RADIO': case 'RADIOS':
                                return (
                                    //en este tipo de campos para ver el error y su mensaje similar a como se hace en campos de tipo TextField mucho mas facil,
                                    //debo hacer una combinación de <FormHelperText y comprobaciones de colores en los diferentes subcomponenetes con field_validation_error
                                    //tal como se muestra a continuación
                                    //quizas sirva para otros tipos de campos que por ahora no ha hecho falta indicar error.
                                    <FormControl 
                                        component="fieldset"
                                        disabled={field_disabled}
                                        sx={{
                                            //background: "beige",
                                            color: field_validation_error ? 'error.main' : 'inherit',
                                            mb   : field_validation_error ? 1            : 'inherit',
                                            ...field.sx
                                            }}
                                        error={field_validation_error}
                                        >

                                        {/* dibujo la label si la hubiera */}
                                        {
                                        (field.label) 
                                        ? (
                                            <FormLabel component="legend"
                                                sx={{ color: field_validation_error ? 'error.main' : 'inherit' }}
                                                >{field.label}</FormLabel>
                                            ) 
                                        : (
                                            null
                                            )
                                        }

                                        <RadioGroup
                                            row // para que todos los options estén en la misma fila
                                            name={field.name}
                                            value={field_value}
                                            //onChange={handleChange}
                                            onChange={FormController_HandleFieldChange(p_stt_FormSchema, p_sstt_FormSchema)}
                                            >
                                            {/* dibujo las options */}                                            
                                            {
                                            (
                                            field.radio_options //compruebo que exista radio_options
                                            && Array.isArray(field.radio_options.labels) && Array.isArray(field.radio_options.values)  //compruebo que sean arrays
                                            && field.radio_options.labels.length === field.radio_options.values.length //compruebo que tengan la misma longitud
                                            ) 
                                            ? (
                                                field.radio_options.values.map((option, index) => (
                                                    <FormControlLabel
                                                        key={index}
                                                        value={option}
                                                        //control={<Radio size="small" />}
                                                        control={<Radio 
                                                                    size="small"
                                                                    sx={{ color: field_validation_error ? 'error.main' : 'inherit', 
                                                                          '&.Mui-checked': { color: field_validation_error ? 'error.main' : 'primary.main' } 
                                                                        }} />
                                                                } 
         


                                                        //label={(field.label[index]) ? field.label[index] :  ''}
                                                        label={field.radio_options?.labels?.[index] ?? ''}
                                                        //se pueden deshabilitar options en particular con la propiedad disabled
                                                        //por lo que, si el field esta deshabilitado en su totalidad (field_disabled) o este option en particular, lo deshabilito
                                                        disabled={(field_disabled || field.radio_options?.disabled?.[index]) ?? false}
                                                    />
                                                ))
                                                ) 
                                            :   (
                                                <Box>Datos de radio no validos</Box>
                                                )
                                            }
                                        </RadioGroup>
                                        {/* para mostrar errores dentro del FormControl, uso... */}
                                        {
                                        field_validation_error && 
                                            <FormHelperText
                                                sx={{ 
                                                    position:'relative', top: '-5px',
                                                 }}
                                                >{field_validation_message}</FormHelperText>
                                        }
                                    </FormControl>
                                        );
                            //break; no hacen falta ya que el return corta la ejecución
                            case 'SELECT':
                                /* if (field.name === "categoria") {
                                    console.log("field.name: ", field.name);
                                    console.log("field.DB_items: ", field.DB_items);
                                    } */
                                if (field && field.DB_items && field.DB_items.length > 0) {
                                    let field_value
                                    // Establece field_value basado en la disponibilidad de field.value o el primer valor en field.DB_items
                                    if (field.value !== null && field.value !== undefined) {
                                        field_value = field.value.toString();
                                        } 
                                    else if (field.DB_items.length > 0) {
                                        field_value = (field.DB_items[0].value !== null && field.DB_items[0].value !== undefined) ? field.DB_items[0].value.toString() : '';
                                        } 
                                    else {
                                        field_value = ''; // Valor predeterminado si no se cumplen las condiciones anteriores
                                        }
                                    //console.log("Valor actual de field_value para el select " + field.label + " [[[" + field_value + "]]]");
                                    //console.log("field_value === null ", field_value === null);
                                    //console.log("typeof field_value", typeof field_value);
                                    //console.log ("field.DB_items: ", JSON.stringify(field.DB_items, null, 4));

                                    return (
                                        // ... Componente para 'SELECT'
                                            <FormControl 
                                                sx = {{mt:2}}
                                                > {/* fullWidth> */}
                                                <InputLabel 
                                                    id={"label-" + field.label}
                                                    >
                                                    {field.label}
                                                </InputLabel>
                                                <Select
                                                    size='small' // ¿funciona?
                                                    name={field.name}
                                                    labelId={"label-" + field.label}
                                                    disabled={field_disabled}
                                                    //id="demo-simple-select"
                                                    //value={field.value ? field.value : field.DB_items?[0].value}
                                                    //value={field.value || ''}
                                                    //value={field.value ? field.value.toString() : (field.DB_items.length > 0 ? field.DB_items[0].value.toString() : '')}
                                                    //value={(field.value ?? '').toString() : (field.DB_items && field.DB_items.length > 0 ? (field.DB_items[0].value ?? '').toString() : '')}
                                                    value= {field_value}
                                                    label={field.label}
                                                    onChange={FormController_HandleFieldChange(p_stt_FormSchema, p_sstt_FormSchema)}
                                                    sx = {{//mt:2, //si pongo esto me descoloca el label, pongo mas arriba en el FormControl
                                                            ...field.sx
                                                            }}
                                                    >
                                                    {field.DB_items && field.DB_items.map((item, index) => (
                                                        <MenuItem key={index} 
                                                            /* value={item.value || ''} */
                                                            value={item.value !== null ? item.value : ''}
                                                            //MenuItem value no adminte null asi que no vale los siguiente....
                                                            //value={(item.value === null || item.value === "null") ? null : (item.value || '')}
                                                            >
                                                            {item.name}
                                                        </MenuItem>
                                                        ))} 
                                                </Select>
                                            </FormControl>
                                            )
                                    }
                                else {
                                    return null
                                    } 
                                //break; no hacen falta ya que el return corta la ejecución
                            case 'DATE':
                                //if (field_value === '') {field_value = "T00:00:00.000Z";}
                                if (field.name == "fecha_nacimiento") {
                                    //console.log ("renderizando una date")
                                    }
                            default:
                                return (
                                        <>
                                        {/* <Box>field_value: {field_value}</Box> */}
                                        <TextField
                                            size={(p_stt_FormSchema.size || 'small') as 'small' | 'medium'}
                                            variant="outlined"
                                            label={field.label}
                                            name={field.name} 
                                            type={field.type}
                                            value={field_value}
                                            margin="normal"
                                            fullWidth={field.type.toLowerCase() == 'textarea'}
                                            multiline={field.type.toLowerCase() == 'textarea'}
                                            rows={field.type.toLowerCase() == 'textarea' ? 6 : undefined}
                                            //cols={field.type.toLowerCase() == 'textarea' ? 40 : undefined} -> no existe cols, lo hago en sx con width
                                            disabled={field_disabled}
                                            // Esto asegura que la etiqueta se mantenga en la posición flotante cuando es date, porque sino superponia la label con el formato de la fecha
                                            InputLabelProps={{
                                                //shrink: (field.type === "DATE") ? true : false
                                                //como no se porque lo anterior no funciona solo para date, lo hago para todos que no queda mal.
                                                shrink: true
                                                }}
                                            //campos para controla el comportamiento (disabled + validación)
                                            required={field.validation?.required}
                                            error={field_validation_error}
                                            helperText={field_validation_message}
                                            //inputProps={field.validation?.inputProps || {}} 
                                            onChange={(event:any) => {FormController_HandleFieldChange(p_stt_FormSchema, p_sstt_FormSchema)(event);  }}
                                            //onChange={HandleFieldChange_with_useCallback}
                                            sx={{
                                                //border:'1px dotted green',
                                                //ml: 5,
                                                width: field.type.toLowerCase() === 'textarea' ? '400px' : 'auto', 
                                                ...field.sx
                                                }}
                                            />
                                        </>
                                        );
                                //break; no hacen falta ya que el return corta la ejecución
                            } // fin del switch
                        })() // fin del return
                    }

                </Box>
            );
        })
        //console.log ("despues del map")
        }
        {
        //RESETEAR si DESHABILITADO
        //lo siguinete define una función con los parenteis granates y la invoca gracias a los parentesis amarillos para que se ejecute justo ahora.
        /* (() => {
            // Inicio del cambio: Comprobar new_FormSchema y ajustar updateNewFormSchema
            if (!isEmpty(new_FormSchema)) {
                update_new_FormSchema = true;
                }
            // Fin del cambio
            })() */
        }
        {
        //Si new_FormSchema se ha inicializado con algo significa que en algún momento de este render se ha decidido cambiar alguno de los valores, 
        //como por ejemplo cuando un campo se deshabilita y por tanto se pone a null su valor para que la interacción con el usuario sea clara.
        //Lo siguinete sería la forma que exige tsx para en este punto hacer una comprobación que en un inicio yo intenté hacer con "if (new_FormSchema) {p_sstt_FormSchema(new_FormSchema)}" pero no funcionaba
        //      new_FormSchema && p_sstt_FormSchema(new_FormSchema)
        //pero esto tampoco funciona tal cual, sino que me obliga a inicializar new_FormSchema con un valor, por ejemplo un objeto vacio {}
        //y eso obliga a variar la condición usando, por ejemplo, isEmpty de lodash
        //!isEmpty(new_FormSchema) && (update_new_FormSchema = true)
        }
        </>
        );  
    }




export function FormController_Configuration (p_stt_FormSchema: iFormController_schema) {
    let str_label;
    let bln_force_fields_disabling = false;
    //'upsert' | 'insert' | 'update' |'delete' | 'get' | 'select' |  'find';
    switch (p_stt_FormSchema.DB_action) {
        case "upsert": 
        str_label = "Guardar"       
            //compruebo a ver si se ha indicado algún pk_field y si tiene valor para saber la etiqueta.
            const pkField = p_stt_FormSchema.fields?.find(field => field.DB_is_pk);
            if (pkField) {
                str_label = pkField.value ? "Modificar" : "Añadir";
                }
            break;
        case "insert": case "create": 
            str_label = "Añadir"; 
            if (p_stt_FormSchema.search_possible_records_to_insert?.possible_records && 
                p_stt_FormSchema.search_possible_records_to_insert?.possible_records.length > 0) {
                str_label = "Atrás";
                }
            break;
        case "update": 
            str_label = "Modificar"; 
            break;
        case "delete": 
            str_label = "Borrar"; 
            bln_force_fields_disabling = true;
            break;
        }

    const str_title_label  = str_label + ' ' + p_stt_FormSchema.name;
    let str_submit_label = str_label;
    //ahora compongo las etiquetas de los posibles botones add y delete
    //podría validar si se ha indicado que existan con p_stt_FormSchema.insert o p_stt_FormSchema.delete
    //pero como "no pide pan" no lo hago
    const str_add_label    = "Añadir " + p_stt_FormSchema.name;
    const str_delete_label = "Borrar " + p_stt_FormSchema.name;

    //si se ha indicado una label la respeto, ignorando lo anterior
    if (p_stt_FormSchema.submit && p_stt_FormSchema.submit.label) {
        str_submit_label = p_stt_FormSchema.submit.label;
        }


    return {
        title_label             : str_title_label,
        submit_label            : str_submit_label,
        force_fields_disabling  : bln_force_fields_disabling,
        add_label               : str_add_label,
        delete_label            : str_delete_label
        }
    
    }



//----------------------------------------------------------------------------------------------------------------------------------
//Usadas en src\components\FormController\FormController_schemas_configure.tsx

export const set_OtherParameters = (formSchema: iFormController_schema, otherParameters?: any) => {
    if (otherParameters) {
        if (otherParameters.show != null) {formSchema.show = otherParameters.show;}
        if (otherParameters.createEnabled != null) {formSchema.createEnabled = otherParameters.createEnabled;}
        if (otherParameters.deleteEnabled != null) {formSchema.deleteEnabled = otherParameters.deleteEnabled;}
        }
    return formSchema;
    };

export const get_prepost_Actions = (p_arr_prepost_Actions: iFormController_schema_function_prepost[]) => { 
    let prepost_Actions: iFormController_schema_functions_CRUD_prepost = {};

    p_arr_prepost_Actions.forEach((prepost_Action) => {
        if (!prepost_Action.on || !prepost_Action.prepost) {
            //console.error("ERROR en get_prepost_Actions, falta el campo 'on' o 'prepost' en prepost_Action", prepost_Action);
            console.log("ERROR en get_prepost_Actions, falta el campo 'on' o 'prepost' en prepost_Action", prepost_Action);
            }
        else {
            if (prepost_Action.on === "insert" || prepost_Action.on === "create" || prepost_Action.on === "upsert") {
                if (prepost_Action.prepost == 'pre' ) {prepost_Actions.onCreate = {...prepost_Actions.onCreate, preAction: prepost_Action};}
                if (prepost_Action.prepost == 'post') {prepost_Actions.onCreate = {...prepost_Actions.onCreate, postAction: prepost_Action};}
                }
            if (prepost_Action.on === "update" || prepost_Action.on === "upsert") {
                if (prepost_Action.prepost == 'pre' ) {prepost_Actions.onUpdate = {...prepost_Actions.onUpdate, preAction: prepost_Action};}
                if (prepost_Action.prepost == 'post') {prepost_Actions.onUpdate = {...prepost_Actions.onUpdate, postAction: prepost_Action};}
                }
            if (prepost_Action.on === "delete") {
                if (prepost_Action.prepost == 'pre' ) {prepost_Actions.onDelete = {...prepost_Actions.onDelete, preAction: prepost_Action};}
                if (prepost_Action.prepost == 'post') {prepost_Actions.onDelete = {...prepost_Actions.onDelete, postAction: prepost_Action};}
                }
            }

        });
    return prepost_Actions;
    }

export const set_FC_schema_configure = (p_stt_FormSchema: iFormController_schema, 
                                        p_sstt_FormSchema: React.Dispatch<React.SetStateAction<iFormController_schema>>,
                                        p_dbData: any, 
                                        p_arr_prepost_Actions: iFormController_schema_function_prepost[], 
                                        p_OtherParameters?: any,
                                        ) => {
    
    const prepost_Actions: iFormController_schema_functions_CRUD_prepost = get_prepost_Actions(p_arr_prepost_Actions);
    let new_FormSchema = FormController_new_stt_FormSchema(p_stt_FormSchema, p_dbData, prepost_Actions);
    new_FormSchema = set_OtherParameters(new_FormSchema, p_OtherParameters);

    p_sstt_FormSchema(new_FormSchema);
    }


