import React, {useState}        from 'react';
import Tooltip                  from '@mui/material/Tooltip';
import { SvgIconProps }         from '@mui/material/SvgIcon';
import Button                   from '@mui/material/Button';

import Box                      from '@mui/material/Box';
import Typography               from '@mui/material/Typography';
import mui_theme                from '@/styles/mui_theme';

/*
como <Tab> esta intimamente relacionado con <Tabs>...
...no se puede usar el mismo truco que con <Icon> para que el tooltip se muestre.
...debe recibir mas propiedades "ocultas" heredadas de tabs
por lo que pongo los props de la siguiente forma, que viene a decir hereda todo lo propio de un tipo de elemento Tab
mas la que te paso para pXS (u otras que pudiera haber futuras)
y asi ya puedo usar la tooltip y las demas propiedades de Tab se heredan sin problemas
*/

interface props {
    pTooltip?: string; // Prop para el texto del tooltip
    //pIcon?: React.ElementType | null;
    pIcon?: React.ReactElement<SvgIconProps> | null;
    pVariant?: string;
    };
const Button_customized: React.FC<props & React.ComponentProps<typeof Button>> = ({
    pTooltip,
    pIcon,
    pVariant,
    ...standardProps // Esto incluirá value, label, y cualquier otra prop que añada
    }) => {
 
    let variant: { [key: string]: any } = {
        default: "outlined",
        solid: "contained",
        };

    let variant_sx: { [key: string]: any } = {
        default: {
                    textTransform: 'capitalize', 
                    //color: 'inherit', // Esto mantiene el color por defecto del texto
                    border: '1px dashed ',
                    boxShadow: 'none',
                },
        solid: {
                border: '1px solid ' + mui_theme.palette.primary.main,
                },
        };

    if (!pVariant) pVariant = "default"; //por defecto 


    // Clonar el icono para añadir estilos
    const pIcon_with_sx = pIcon 
        ? React.cloneElement(pIcon, {
                                    sx: {
                                        // Añade aquí los estilos que desees para el icono
                                        // Ejemplo:
                                        //fontSize: '.12rem', // Cambia el tamaño del icono
                                        fontSize: '.9rem!important', // Cambia el tamaño del icono
                                        //mr: "0px!important", // Margen derecho para separar del texto no lo consigo hacer funcionar
                                        position:"relative", left: "4px", 
                                        //color: 'yellow', // Hereda el color del padre
                                        ...pIcon.props.sx, // Respeta los estilos 'sx' originales pasados al icono
                                    }
                                }) 
        : null;

    return (
        <Tooltip 
            title={
                    <Typography 
                        sx={{
                            whiteSpace: 'pre-line', 
                            ...mui_theme.customized.MuiTooltip
                            }}
                        >
                        {pTooltip}
                    </Typography>
                    } 
            disableHoverListener={!pTooltip}
            sx={{ typography: 'body1' }} // Esto asegura que se respeten los saltos de línea cuando los marco con \n

            //VER src\styles\mui_theme.ts > * hide_between_tooltips
            slotProps   ={mui_theme.customized.MuiTooltip.hide_between_tooltips.slotProps}
            PopperProps ={mui_theme.customized.MuiTooltip.hide_between_tooltips.PopperProps}
            >
            
            <span className = 'span_para_botones_deshabilitados'> {/* Envoltorio para el Tooltip que permite que funcione en botones deshabilitados */}
            {/* <Box> Button_customized 0</Box> */}
            <Button
                {...standardProps} //importante poner esta la primera para que no se machaque los sx que pongo a continuacion
                sx={{
                    //p: 1, //pongo aqui los estilos particulares que quiera
                    //...mui_theme.customized.MuiButton, ...esto no va, por ahora no lo peleo
                    //border: "2px solid red",
                    boxShadow: 'none',
                    fontSize: '0.8rem',
                    //position: "relative", top: "3px",
                    borderColor: (standardProps.disabled) ? "silver" : mui_theme.palette.primary.light,                    
                    ...variant_sx[pVariant], // Añade los estilos específicos del tipo de botón
                    ...standardProps.sx,
                    //he intentado lo siguiente para mover el texto del boton algo mas abajo, que me parece que no está centrado pero con eso muevo todo el boton
                    //no se como mover solo el texto
                    //'&.MuiButton-root': {position: "relative", top: "3px!important",}, 
                    }}
                startIcon={pIcon_with_sx}
                variant={variant[pVariant]}
                />
            {/* <Box> Button_customized 1</Box> */}
            </span>
        </Tooltip>
    );
};

export default Button_customized;
