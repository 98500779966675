import { iFormController_schema }     from '@/libs/interfaces';

const Fechas_Alojamiento_width = '180px';


export const FC_schema_Championship: iFormController_schema = {
    size: 'small',
    name: 'Campeonato',
    DB_table: 'TB_CAMPEONATOS',
    DB_action: 'upsert',
    createEnabled: false,
    deleteEnabled: false,
    fields: [
        {
            name      : "codigo",
            type      : "hidden",
            label     : "Nombre",
            DB_field  : "COD_CAMPEONATO",
            DB_is_pk  : true,
        },
        {
            name      : "nombre",
            //value     : "",
            type      : "text",
            label     : "Nombre",
            DB_field  : "NOMBRE_CAMPEONATO",
            disabled  : true,
            validation: { required: true,
                required_message: "Debes introducir un nombre",
                        }
        },
        {
            name      : "tipo",
            //value     : "Liga simple",
            type      : "number",
            label     : "Tipo de Campeonato",
            DB_field  : "COD_TIPO_DE_CAMPEONATO",
            disabled  : true,
        },
        {
            name      : "num_equipos",
            //value     : "12",
            type      : "number",
            label     : "Núm. de Equipos",
            DB_field  : "NUM_EQUIPOS",
            disabled  : true,
        },
        {
            name      : "num_grupos",
            //value     : "1",
            type      : "number",
            label     : "Núm de Grupos",
            DB_field  : "NUM_GRUPOS",
            disabled  : true,
        },
        {
            name      : "temporada",
            //value     : "2024",
            type      : "number",
            label     : "Temporada",
            DB_field  : "COD_TEMPORADA",
            disabled  : true,
        },
        {
            name      : "habilitado",
            //value     : true,
            type      : "switch",
            label     : "Habilitado",
            DB_field  : "CAMPEONATO_HABILITADO",
            //disabled  : true,
        },
        {
            name      : "fechas_inicio_y_fin",
            type      : "label",
            //label     : "Fechas de Inicio y Fin",
            label     : "",
            box_sx    : {border: "0px solid pink", mt:3},
        },
        {
            name      : "fecha_inicio",
            //value     : "2023-12-01", //formato valido: aaaa-mm-dd
            type      : "date",
            label     : "Fecha Inicio",
            DB_field  : "FECHA_INICIO_CAMPEONATO", 
            validation: { required: true },
        },
        {
            name      : "fecha_fin",
            //value     : "2023-12-01", //formato valido: aaaa-mm-dd
            type      : "date",
            label     : "Fecha Fin",
            DB_field  : "FECHA_FIN_CAMPEONATO", 
            validation: { required: true },
        },
        {
            name      : "fechas_entrada_y_salida_alojamiento",
            type      : "label",
            //label     : "Fechas de Entrada y Salida del Alojamiento",
            label     : "",
            box_sx    : {border: "0px solid red", mt: 3},
        },
        {
            name      : "fecha_entrada_alojamiento_campeonato",
            //value     : "", //formato valido: aaaa-mm-dd
            type      : "date",
            label     : "Fecha Entrada Alojamiento", // CAMPEONATO",
            DB_field  : "FECHA_ENTRADA_ALOJAMIENTO_CAMPEONATO", 
            //disabled  : true,
            validation: { required: true },
            sx: {width: Fechas_Alojamiento_width}
        },
        {
            name      : "fecha_salida_alojamiento_campeonato",
            //value     : "", //formato valido: aaaa-mm-dd
            type      : "date",
            label     : "Fecha Salida Alojamiento", // CAMPEONATO",
            DB_field  : "FECHA_SALIDA_ALOJAMIENTO_CAMPEONATO", 
            //disabled  : true,
            validation: { required: true },
            sx: {width: Fechas_Alojamiento_width}
        },
        ],
    submit: {
        //label: "Guardar",
        disabled: true,
        }
    }

export const FC_schema_Team: iFormController_schema = {
    size: 'small',
    name: 'Equipo',
    DB_table: 'TB_EQUIPOS',
    DB_action: 'upsert',
    fields: [
        {
            name      : "codigo",
            type      : "hidden",
            DB_field  : "COD_EQUIPO",
            DB_is_pk  : true,
        },
        {
            name      : "nombre",
            //value     : "",
            type      : "text",
            label     : "Nombre",
            DB_field  : "NOMBRE_EQUIPO",
            //disabled  : true,
            validation: { required: true,
                          required_message: "Debes introducir un nombre",
                        }
        },
        {
            name      : "descripcion",
            //value     : "",
            type      : "textarea",
            label     : "Descripcion",
            DB_field  : "DESC_EQUIPO",
            //disabled  : true,
        },
        {
            name      : "direccion",
            //value     : "",
            type      : "text",
            label     : "Dirección",
            DB_field  : "DIRECCION_EQUIPO",
            //disabled  : true,
        },
        {
            name      : "telefono",
            //value     : "1",
            type      : "text",
            label     : "Teléfono",
            DB_field  : "TELEFONO_EQUIPO",
            //disabled  : true,
        },
        {
            name      : "email",
            //value     : "", //formato valido: aaaa-mm-dd
            type      : "text",
            label     : "Email",
            DB_field  : "EMAIL_EQUIPO", 
            //disabled  : true,
        },
        {
            name      : "categoria",
            //value     : 3, //para pruebas
            type      : "select",
            label     : "Categoría",
            DB_field  : "TBREL_EQUIPOS_CAMPEONATOS.COD_CATEGORIA", 
            //DB_items : [{value: 1, text: "uno"}, {value: 2, text: "dos"}, {value: 3, text: "tres"}]
            DB_items : [],
            //first_item: "Sin Club2",
            //disabled  : true,
            sx: {width: '150px'},
        },
        {
            name      : "club",
            //value     : 3, //para pruebas
            type      : "select",
            label     : "Club",
            DB_field  : "COD_CLUB", 
            //DB_items : [{value: 1, text: "uno"}, {value: 2, text: "dos"}, {value: 3, text: "tres"}]
            DB_items : [],
            //first_item: "Sin Club2",
            //disabled  : true,
        },
        {
            name      : "fechas_entrada_y_salida_alojamiento",
            type      : "label",
            //label     : "Fechas de Entrada y Salida del Alojamiento",
            label     : "",
            box_sx    : {border: "0px solid red", mt: 3},
        },
        {
            name      : "fecha_entrada_alojamiento_equipo",
            //value     : "", //formato valido: aaaa-mm-dd
            type      : "date",
            label     : "Fecha Entrada Alojamiento", // EQUIPO",
            DB_field  : "TBREL_EQUIPOS_CAMPEONATOS.FECHA_ENTRADA_ALOJAMIENTO_EQUIPO", 
            initialize_on_create: true,
            sx        : {width: Fechas_Alojamiento_width}
        },
        {
            name      : "fecha_salida_alojamiento_equipo",
            //value     : "", //formato valido: aaaa-mm-dd
            type      : "date",
            label     : "Fecha Salida Alojamiento", // EQUIPO",
            DB_field  : "TBREL_EQUIPOS_CAMPEONATOS.FECHA_SALIDA_ALOJAMIENTO_EQUIPO", 
            initialize_on_create: true,
            sx        : {width: Fechas_Alojamiento_width}
        },
        ],
    submit: {
        //label: "Guardar",
        //disabled: true,
        }
    }

export const FC_schema_Accommodation: iFormController_schema = {
    show: false,
    size: 'small',
    name: 'Alojamiento',
    DB_table  : "TB_ALOJAMIENTOS",
    DB_action: "upsert", // tDBAction
    fields: [
        {
            name      : "codigo",
            //value     : "",
            type      : "hidden",
            DB_field  : "COD_ALOJAMIENTO",
            DB_is_pk  : true,
        },
        {
            name      : "nombre",
            //value     : "",
            type      : "text",
            label     : "Nombre",
            DB_field  : "NOMBRE_ALOJAMIENTO",
            //disabled  : true,
            validation: { required: true, required_message: "Debes introducir un nombre"}
        },
        {
            name      : "desc",
            //value     : "",
            type      : "textarea",
            label     : "Descripción",
            //DB_field  : "DESC_ALOJAMIENTO", //no lo guardo en esta tabla sino en TBREL_CLIENTES_TEMPORADAS_ALOJAMIENTOS
            DB_field  : "TBREL_CLIENTES_TEMPORADAS_ALOJAMIENTOS.DESC_ALOJAMIENTO_TEMPORADA_CLTE", 
            //disabled  : true,
            //validation: { required: true, required_message: "Debes introducir un nombre"}
        },
        {
            name      : "num_habitaciones_maximo",
            type      : "number",
            label     : "Núm. Habitaciones Máximo",
            DB_field  : "NUM_HABITACIONES_MAXIMO",
            value     : 0, //importante para que no de casque la insercción
        },
        {
            //name      : "num. habitaciones ocupadas",
            name      : "num_habitaciones_creadas",
            type      : "hidden",
            DB_field  : "NUM_HABITACIONES_CREADAS",
            value     : 0, //importante para que no de casque la insercción
        },
        {
            //name      : "num. habitaciones sin crear",
            name      : "num_habitaciones_sin_crear",
            type      : "hidden",
            DB_field  : "NUM_HABITACIONES_SIN_CREAR",
            value     : 0, //importante para que no de casque la insercción
        },
        ],
    submit: {
        //label: "Guardar",
        //disabled: true,
        },
    
    }

export const FC_schema_RoomType: iFormController_schema = {
    show: false,
    size: 'small',
    name: 'Tipo de Habitación',
    DB_table  : "TB_TIPOS_DE_HABITACION",
    DB_action: "upsert", // tDBAction
    fields: [
        {
        name      : "codigo",
        //value     : "",
        type      : "hidden",
        DB_field  : "COD_TIPO_DE_HABITACION",
        DB_is_pk  : true,
        },
        {
        name      : "nombre",
        value     : "",
        type      : "text",
        label     : "Nombre",
        DB_field  : "NOMBRE_TIPO_DE_HABITACION",
        validation: { required: true, required_message: "Debes introducir un nombre"}
        },
        {
        name      : "desc",
        //value     : "",
        type      : "textarea",
        label     : "Descripción",
        DB_field  : "DESC_TIPO_DE_HABITACION",
        },
        {
        name      : "num_plazas",
        value     : 1,
        type      : "number",
        label     : "Número de plazas",
        DB_field  : "NUM_PLAZAS_TIPO_DE_HABITACION",
        validation: { required: true, required_message: "Debes introducir un numero de plazas > 0"}
        },
        {
        name      : "precio",
        value     : 0.0,
        type      : "number",
        label     : "Precio",
        DB_field  : "PRECIO_TIPO_DE_HABITACION",
        },
        {
        name                : "cod_alojamiento",
        //value     : props.pAccommodation.COD_ALOJAMIENTO,
        type                : "hidden",
        //label     : "cod_alojamiento",
        DB_field            : "COD_ALOJAMIENTO",
        initialize_on_create: true,
        },
        ],
    }

export const FC_schema_Room: iFormController_schema = {
    show: false,
    size: 'small',
    name: 'Habitación',
    DB_table  : "TB_HABITACIONES",
    DB_action: "upsert", // tDBAction
    fields: [
        {
            name      : "codigo",
            //value     : "",
            type      : "hidden",
            DB_field  : "COD_HABITACION",
            DB_is_pk  : true,
        },
        {
            name      : "numero",
            value     : "",
            type      : "text",
            label     : "Número",
            DB_field  : "NUM_HABITACION",
            validation: { required: true, required_message: "Debes introducir un nombre"}
        },
        {
            name      : "desc",
            //value     : "",
            type      : "textarea",
            label     : "Descripción",
            DB_field  : "DESC_HABITACION",
        },
        {
            name      : "cod_alojamiento",
            value     : null,
            type      : "hidden",
            DB_field  : "COD_ALOJAMIENTO",
            initialize_on_create: true,
        },
        {
            name      : "cod_tipo_de_habitacion",
            value     : null,
            type      : "hidden",
            DB_field  : "COD_TIPO_DE_HABITACION",
            initialize_on_create: true,
        },
        {
            name      : "club",
            //value     : 3, //para pruebas
            type      : "select",
            label     : "Club",
            DB_field  : "COD_CLUB", 
            //DB_items : [{value: 1, text: "uno"}, {value: 2, text: "dos"}, {value: 3, text: "tres"}]
            DB_items : [],
            //first_item: "Sin Club2",
            //disabled  : true,
        },
        ],
    }

export const FC_schema_Club: iFormController_schema = {
    show: false,
    size: 'small',
    name: 'Club',
    DB_table  : "TB_CLUBS",
    DB_action: "upsert", // tDBAction
    fields: [
        {
            name      : "codigo",
            //value     : "",
            type      : "hidden",
            //type      : "text", disabled  : true,
            DB_field  : "COD_CLUB",
            DB_is_pk  : true,
        },
        {
            name      : "cliente",
            //value     : "",
            type      : "hidden",
            DB_field  : "COD_CLTE",
            initialize_on_create: true,
        },
        {
            name      : "nombre",
            //value     : "",
            type      : "text",
            label     : "Nombre",
            DB_field  : "NOMBRE_CLUB",
            //disabled  : true,
            validation: { required: true}
        },
        {
            name      : "cif",
            //value     : "",
            type      : "text",
            label     : "C.I.F.",
            DB_field  : "CIF_CLUB",
            //disabled  : true,
            //validation: { required: true}
        },
        {
            name      : "procedencia",
            //value     : "",
            type      : "text",
            label     : "Procedencia",
            DB_field  : "PROCEDENCIA_CLUB",
            //disabled  : true,
            //validation: { required: true}
        },
        {
            name      : "desc",
            //value     : "",
            type      : "textarea",
            label     : "Descripción",
            DB_field  : "DESC_CLUB",
            //disabled  : true,
            //validation: { required: true, required_message: "Debes introducir un nombre"}
        },
        {
            name      : "num_plazas_alojamiento",
            label     : "Núm. Plazas de Alojamiento",
            //value     : "",
            type      : "number",
            DB_field  : "NUM_PLAZAS_ALOJAMIENTO",
        },
        {
            name      : "fechas_entrada_y_salida_alojamiento",
            type      : "label",
            //label     : "Fechas de Entrada y Salida del Alojamiento",
            label     : "",
            box_sx    : {border: "0px solid red", mt: 3},
        },
        {
            name      : "fecha_entrada_alojamiento_club",
            //value     : "", //formato valido: aaaa-mm-dd
            type      : "date",
            label     : "Fecha Entrada Alojamiento", // CLUB",
            DB_field  : "TBREL_CLUBS_CAMPEONATOS.FECHA_ENTRADA_ALOJAMIENTO_CLUB", 
            validation: { required: true}, //NO VA POR AHORA
            initialize_on_create: true,
            sx        : {width: Fechas_Alojamiento_width}
        },
        {
            name      : "fecha_salida_alojamiento_club",
            //value     : "", //formato valido: aaaa-mm-dd
            type      : "date",
            label     : "Fecha Salida Alojamiento", // CLUB",
            DB_field  : "TBREL_CLUBS_CAMPEONATOS.FECHA_SALIDA_ALOJAMIENTO_CLUB", 
            validation: { required: true}, //NO VA POR AHORA
            initialize_on_create: true,
            sx        : {width: Fechas_Alojamiento_width}
        },
            
        ],
    }

export const FC_schema_Player: iFormController_schema = {
    size: 'small',
    name: 'Jugador',
    DB_table: 'TB_JUGADORES',
    DB_action: 'upsert',
    createEnabled: true,
    deleteEnabled: true, 
    fields: [
        {
            name      : "codigo",
            type      : "hidden",
            DB_field  : "COD_JUGADOR",
            DB_is_pk  : true,
        },
        {
            name      : "nombre",
            type      : "text",
            label     : "Nombre",
            DB_field  : "NOMBRE_JUGADOR",
            validation: { required: true,
                          required_message: "Debes introducir un nombre",
                        }
        },
        {
            name      : "apellido1",
            type      : "text",
            label     : "Apellido 1",
            DB_field  : "APELLIDO1_JUGADOR",
            validation: { required: true,
                          required_message: "Debes introducir un apellido",
                        }
        },
        {
            name      : "apellido2",
            type      : "text",
            label     : "Apellido 2",
            DB_field  : "APELLIDO2_JUGADOR",
        },
        {
            name      : "dni",
            type      : "text",
            label     : "DNI / Pasaporte / ...",
            validation: { required: true, required_message: "Debes introducir un documento identificativo.", },
            DB_field  : "DNI_JUGADOR",
        },
        {
            name      : "descripcion",
            type      : "textarea",
            label     : "Descripcion",
            DB_field  : "DESC_JUGADOR",
        },
        {
            name      : "telefono",
            type      : "text",
            label     : "Teléfono",
            DB_field  : "TELEFONO_JUGADOR",
        },
        {
            name      : "email",
            type      : "text",
            label     : "Email",
            DB_field  : "EMAIL_JUGADOR", 
        },
        {
            name      : "fecha_nacimiento",
            type      : "date",
            label     : "Fecha Nacimiento",
            validation: { required: true, required_message: "Es necesaria para validar su categoría.", },
            DB_field  : "FECHA_NACIMIENTO_JUGADOR", 
        },
        {
            name      : "apodo",
            type      : "text",
            label     : "Apodo",
            DB_field  : "NOMBRE_DEPORTIVO_JUGADOR", 
        },
        ],
    search_possible_records_to_insert: {
        function_search_name: "possible_TB_JUGADORES_to_insert",
        //function_search_params: {}, //se define en src\components\FormController\FormController_schemas_configure.tsx
        buttons_component: "${NOMBRE_JUGADOR} ${APELLIDO1_JUGADOR} ${APELLIDO2_JUGADOR} (${DNI_JUGADOR}). {{DEV: ${COD_JUGADOR} - ${NIVEL_COINCIDENCIA} - ${EXISTE} }}",
        function_insert_name: "post_TB_JUGADORES_insert",
        //function_insert_COD_field_name: "COD_JUGADOR"
        }
    }

export const FC_schema_Delegate: iFormController_schema = {
    size: 'small',
    name: 'Delegado',
    DB_table: 'TB_DELEGADOS',
    DB_action: 'upsert',
    createEnabled: true,
    deleteEnabled: true,    
    fields: [
        {
            name      : "codigo",
            type      : "hidden",
            DB_field  : "COD_DELEGADO",
            DB_is_pk  : true,
        },
        {
            name      : "nombre",
            type      : "text",
            label     : "Nombre",
            DB_field  : "NOMBRE_DELEGADO",
            validation: { required: true, minLength: 2}
        },
        {
            name      : "apellido1",
            type      : "text",
            label     : "Apellido 1",
            DB_field  : "APELLIDO1_DELEGADO",
            validation: { required: true, minLength: 2}
        },
        {
            name      : "apellido2",
            type      : "text",
            label     : "Apellido 2",
            DB_field  : "APELLIDO2_DELEGADO",
            //validation: { required: true, minLength: 2}
        },
        {
            name      : "dni",
            type      : "text",
            label     : "DNI / Pasaporte / ...",
            validation: { required: true, required_message: "Debes introducir un documento identificativo.", },
            DB_field  : "DNI_DELEGADO",
        },
        {
            name      : "telefono",
            type      : "text",
            label     : "Teléfono",
            DB_field  : "TELEFONO_DELEGADO",
        },
        {
            name      : "fecha_nacimiento",
            type      : "date",
            label     : "Fecha Nacimiento",
            DB_field  : "FECHA_NACIMIENTO_DELEGADO", 
            validation: { required: true } //no se aplica en este tipo, ver que pasa
        },
        {
            name      : "email",
            type      : "text",
            label     : "Email",
            DB_field  : "EMAIL_DELEGADO", 
            //Ponemos obligatorio el email por si queremos que sea usuario y por tanto es necesario para hacer login.
            //lo ideal seria que required estuviera condicionado al estado del campo es_usuario de forma similar a como se hace con disabled pero por ahora no lo he implementado.
            validation: { required: true, required_message: "En los delegados se debe indicar el email." },
        },
        {
            name      : "es_usuario",
            //value     : true,
            type      : "switch",
            label     : "Es Usuario...",
            DB_field  : "ES_USUARIO",
            //disabled  : true,
        },
        {
            name            : "tipo_usuario",
            type            : "radios",
            radio_options   : {
                                labels: ["...de equipo", "...de club"],
                                values: ["equipo", "club"],
                                //disabled: [false, true],
                                },
            DB_field  : "",
            validation: { required: true, required_message: "Debes indicar que tipo de Usuario es" },
            sx        : {ml: 5},
            disabled: [
                {
                //operator: "OR",
                //rules: [{ field: "ES_USUARIO", value: false }, { field: "ES_USUARIO", value: null }, { field: "ES_USUARIO", value: undefined }]
                rules: [{ field: "ES_USUARIO", operator: "!=", value: true }]
                }
              ],
        },
        {
            name      : "clave_usuario",
            //value     : true,
            type      : "text",
            label     : "Clave",
            DB_field  : "TB_USUARIOS.CLAVE_USUARIO_desencriptada", 
            sx        : {ml: 5, mt:1},
            disabled: [
                {
                    //operator: "OR",
                    //rules: [{ field: "ES_USUARIO", value: false }, { field: "ES_USUARIO", value: null }, { field: "ES_USUARIO", value: undefined }]
                    rules: [{ field: "ES_USUARIO", operator: "!=", value: true }]
                }
              ],
            validation: { 
                required: true, required_message: "Debes introducir una clave para el Usuario",
                minLength: 5,
                }
        },

        //he intentado hacer la UI con 2 checkbox autoexcluyente pero al no ser una de ellas un campo de la tabla 
        //como es el caso anterior con ES_USUARIO se complica, asi que uso el caso anterior
        /* {
            name      : "tiene_usuario",
            type      : "label",
            label     : "Tiene Usuario",
            box_sx    : {border: "0px solid pink", display: "inline-block", position:"relative", top: "7px", mr:2},
        },
        {
            name      : "es_usuario_de_equipo",
            type      : "checkbox",
            label     : "...de equipo",
            box_sx    : {border: "0px dashed pink", display: "inline"},
        },

        {
            name      : "es_usuario_de_club",
            type      : "checkbox",
            label     : "...de club",
            box_sx    : {border: "0px dotted pink", display: "inline"},
        }, */

        ],
        search_possible_records_to_insert: {
            function_search_name: "possible_TB_DELEGADOS_to_insert",
            //function_search_params: {}, //se define en src\components\FormController\FormController_schemas_configure.tsx
            buttons_component: "${NOMBRE_DELEGADO} ${APELLIDO1_DELEGADO} ${APELLIDO2_DELEGADO} (${DNI_DELEGADO}). {{DEV: ${COD_DELEGADO} - ${NIVEL_COINCIDENCIA} - ${EXISTE} }}",
            function_insert_name: "post_TB_DELEGADOS_insert",
            //function_insert_COD_field_name: "COD_JUGADOR"
            }
    }

export const FC_schema_Companion: iFormController_schema = {
    size: 'small',
    //name: 'Acompaniante',
    name: 'Acompañante',
    DB_table: 'TB_ACOMPANIANTES',
    DB_action: 'upsert',
    createEnabled: true,
    deleteEnabled: true,   
    fields: [
        {
            name      : "codigo",
            type      : "hidden",
            DB_field  : "COD_ACOMPANIANTE",
            DB_is_pk  : true,
        },
        {
            name      : "nombre",
            type      : "text",
            label     : "Nombre",
            DB_field  : "NOMBRE_ACOMPANIANTE",
            validation: { required: true, required_message: "Debes introducir un nombre" }
        },
        {
            name      : "apellido1",
            type      : "text",
            label     : "Apellido 1",
            DB_field  : "APELLIDO1_ACOMPANIANTE",
            validation: { required: true, required_message: "Debes introducir un apellido" }
        },
        {
            name      : "apellido2",
            type      : "text",
            label     : "Apellido 2",
            DB_field  : "APELLIDO2_ACOMPANIANTE",
        },
        {
            name      : "dni",
            type      : "text",
            label     : "DNI / Pasaporte / ...",
            validation: { required: true, required_message: "Debes introducir un documento identificativo.", },
            DB_field  : "DNI_ACOMPANIANTE",
        },
        {
            name      : "telefono",
            type      : "text",
            label     : "Teléfono",
            DB_field  : "TELEFONO_ACOMPANIANTE",
        },
        {
            name      : "email",
            type      : "text",
            label     : "Email",
            DB_field  : "EMAIL_ACOMPANIANTE", 
        },
        {
            name      : "fecha_nacimiento",
            type      : "date",
            label     : "Fecha Nacimiento",
            DB_field  : "FECHA_NACIMIENTO_ACOMPANIANTE", 
        },
        ],
    search_possible_records_to_insert: {
        function_search_name: "possible_TB_ACOMPANIANTES_to_insert",
        //function_search_params: {}, //se define en src\components\FormController\FormController_schemas_configure.tsx
        buttons_component: "${NOMBRE_ACOMPANIANTE} ${APELLIDO1_ACOMPANIANTE} ${APELLIDO2_ACOMPANIANTE} (${DNI_ACOMPANIANTE}). {{DEV: ${COD_ACOMPANIANTE} - ${NIVEL_COINCIDENCIA} - ${EXISTE} }}",
        function_insert_name: "post_TB_ACOMPANIANTES_insert",
        //function_insert_COD_field_name: "COD_JUGADOR"
        }
    }

export const FC_schema_Login: iFormController_schema = {
    size: 'small',
    name: 'Login',
    DB_table: 'TB_USUARIOS',
    DB_action: 'login',
    //loginAction: 'TB_USUARIOS_Login', //al dejarla vacia tira de NextAuth
    //createEnabled: true,
    //deleteEnabled: true,    
    fields: [
        {
            name      : "email",
            type      : "text",
            label     : "EMail",
            DB_field  : "EMAIL_USUARIO",
            validation: { required: true, minLength: 4, required_message: "Introduce un email", minLength_message: "...al menos 4 caracteres"}
        },
        {
            name      : "password",
            type      : "password",
            label     : "Password",
            DB_field  : "CLAVE_USUARIO",
            validation: { required: true, minLength: 4, required_message: "Introduce una contraseña", minLength_message: "...al menos 4 caracteres"}
        },
        ],
    submit: {
        label: "Aceptar",        
        //disabled: true,
        } 
    }